<script>
import Loader from "vue-element-loading";

import api from "@/api";
import NotificationMixin from "@/mixins/Notification.js";

export default {
  name: "Notifications",
  props: ["logout"],
  components: {
    Loader,
  },
  data() {
    var hn = false;
    var cn = false;
    if ("Notification" in window) {
      cn = true;
      hn = Notification.permission === "granted";
      // console.log(Notification)
    }
    return {
      can_notify: cn,
      has_notifications: hn,
      pageSize: 10,
      hasMoreNotifications: false,
      loadingMoreNotifactions: false,
      moreNotifications: [],
      page: 2,
    };
  },
  methods: {
    loadMoreNotifications() {
      const url = `/notifications/?page=${this.page}&page_size=${this.pageSize}`;
      return api.Util.axios
        .get(url)
        .then((response) => {
          if (!response.data.next) {
            this.hasMoreNotifications = false;
          }
          for (const notif of response.data.results) {
            this.moreNotifications.push(notif);
          }
          this.page += 1;
        })
        .catch((err) => {
          alert("Error loading more notifications. Try again later.");
          console.error(err);
        });
    },
    getLinkParams(notif) {
      return NotificationMixin.getLinkParams(notif);
    },
    enable_notifications() {
      this.can_notify = false;
      var p = this;
      function f(permission) {
        if (permission === "granted") {
          p.has_notifications = true;
        }
      }
      Notification.requestPermission(f).then(f);
    },
    mark() {
      this.$store.dispatch("General/updateLoading", true);
      this.$store
        .dispatch("General/markAllNotificationsRead", false)
        .then(() => {
          this.$store.dispatch("General/getNotifications");
          this.$store.dispatch("General/updateLoading", false);
        });
    },
    redirectClick(notif) {
      console.log(1);
      console.log(notif);
      const url = `/notifications/${notif.id}/`;
      api.Util.axios.patch(url, { seen: 1 });
      const params = NotificationMixin.getLinkParams(notif);
      console.log(params);
      this.$router.push(params);
    },
  },
  computed: {
    loading() {
      return this.$store.state.General.loadingNotifications;
    },
    loggedIn() {
      return this.$store.state.Auth.loggedIn;
    },
    notifications() {
      const finalNotifs = [];
      for (const notif of this.$store.state.General.notifications) {
        finalNotifs.push(notif);
      }
      for (const notif of this.moreNotifications) {
        finalNotifs.push(notif);
      }
      return finalNotifs;
    },
  },
  mounted() {
    function urlB64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const rawData = atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    }

    async function saveSubscription(subscription) {
      const token = localStorage.getItem("tiziAuthToken");
      if (!token) {
        console.log("no token");
        return;
      }

      const response = api.Util.axios.post("/saveSubscription", {
        body: JSON.stringify(subscription),
      });
      return response.json();
    }

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/static/dist/assets/js/service.js")
        .then(async function (reg) {
          const subscription = await reg.pushManager.getSubscription();
          if (subscription) {
            await saveSubscription(subscription);
            return;
          }

          try {
            const options = {
              applicationServerKey: urlB64ToUint8Array(
                "BDh7D8w9NgyAH4XEApvHc9aC0HkzkWeQnJQD2S_ddaVjQ846YbCeu7vwtVY-DEpgxvdXMNVvyEEO8dzWsPBfFFM"
              ),
              userVisibleOnly: true,
            };
            const subscription = await reg.pushManager.subscribe(options);
            await saveSubscription(subscription);
            return;
          } catch (err) {
            console.error(err);
          }
        });
    }
  },
  created() {
    if (!this.$store.getters["Auth/getLoggedIn"]) {
      this.$router.push("/");
      return;
    }
    this.$store
      .dispatch("General/getNotifications", {
        page: 1,
        pageSize: this.pageSize,
      })
      .then((data) => {
        const next = data.next;
        if (next) {
          this.hasMoreNotifications = true;
        }
      });
  },
};
</script>

<template>
  <div id="Notifications" class="container">
    <div class="row justify-content-center" id="btnMark">
      <Loader :active="loading" />

      <div class="col">
        <h4>Notifications</h4>
      </div>

      <div class="col text-center">
        <button class="btn btn-success" @click="mark">Mark All As Read</button>
      </div>
    </div>

    <template v-if="notifications.length">
      <template v-for="notif in notifications">
        <a
          href="#"
          class="row notification"
          :class="notif.seen ? '' : 'unseen'"
          @click.prevent="redirectClick(notif)"
          :key="notif.id"
        >
          <div class="col-8">
            <i class="fas fa-circle text-success" v-if="!notif.seen"></i>
            <template v-if="notif.type === 'Schedule'">
              <i class="fas fa-clock"></i>
            </template>
            <template v-if="notif.source_name"
              >From: <span class="name fw-bold">{{ notif.source_name }}</span
              >:</template
            >
            <span v-html="notif.message"></span>
          </div>
          <div class="col text-right date">
            {{ notif.date }}
          </div>
        </a>
      </template>
    </template>

    <template v-else>
      <div class="row notification">
        <div class="col">Nothing to see here folks!</div>
      </div>
    </template>

    <template v-if="hasMoreNotifications">
      <div class="row mt-2 justify-content-center" id="seeMore">
        <div class="col text-center">
          <a
            href="#"
            class="text-secondary"
            @click.prevent="loadMoreNotifications"
            >See More Notifications</a
          >
        </div>
      </div>
    </template>

    <!-- <transition appear enter-active-class="animated bounceInUp slower delay-2s" leave-active-class="animated bounceOutDown">
          <div v-if="loggedIn && can_notify && !has_notifications" id="enable_notifications">
            Get notified when booking is confirmed/up-coming? <br/>
            <a class="btn btn-success" @click='enable_notifications' href="#">
              Click to Turn on Notifications.
            </a>
          </div>
        </transition> -->
  </div>
</template>

<style lang="scss" scoped>
#enable_notifications {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 70px;
  background-color: white;
  padding: 12px;
  box-shadow: black 0px 10px 50px 0px;
  text-align: center;
  .btn {
    margin-top: 3px;
    padding: 1px 8px;
  }
}

#Notifications {
  margin-top: 10px;
}
#seeMore {
  font-size: 14px;
}
.notification {
  border: 1px solid #eee;
  cursor: pointer;
  text-decoration: none;
  background-color: #fff;
  padding-top: 9px;
  padding-bottom: 9px;
  cursor: pointer;
  transition: 0.2s all;
  .fa-circle {
    transform: scale(0.7);
    display: inline-block;
    margin-right: 3px;
  }
  .date {
    color: black;
  }
  a {
    text-decoration: none;
  }
  &:hover {
    background-color: #eee;
    border-color: #ccc;
  }
  &.unseen {
    background-color: #edfff1;
    &:hover {
      color: white;
      .date,
      a {
        color: white;
      }
      background-color: #4caf50;
    }
  }
}
#btnMark {
  margin-top: 3px;
  margin-bottom: 10px;
}
.name {
  color: green;
}
</style>
